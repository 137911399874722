import { useLocation } from '@reach/router';
import _getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * @function useQueryString
 * @description custom hook that return value from query string
 */
function useQueryString() {
  const location = useLocation();

  const getQueryValue = (key, fallback) => {
    return _getQueryValue(location, key) || fallback;
  };

  return { getQueryValue };
}

export default useQueryString;
